var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('general-button',{attrs:{"btn_type":"info","btn_block":"","btn_disabled":_vm.bill_btn_disabled},on:{"click-event":_vm.createData},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-receipt-text-check")])]},proxy:true}])},[_vm._v("一括作成")])],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('general-button',{attrs:{"btn_color":"error","btn_block":"","btn_disabled":_vm.bill_btn_disabled},on:{"click-event":_vm.deleteData},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-receipt-text-remove")])]},proxy:true}])},[_vm._v("一括削除")])],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('general-button',{attrs:{"btn_color":"success","btn_block":"","btn_disabled":_vm.bill_btn_disabled},on:{"click-event":_vm.newItem},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-box")])]},proxy:true}])},[_vm._v("明細追加")])],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"（キーワード検索）","single-line":"","hide-details":"","clearable":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts,"search":_vm.search,"item-key":"id","mobile-breakpoint":0,"items-per-page":100,"footer-props":{
      'items-per-page-options': [100, 200, 400, -1],
      'items-per-page-text': '表示件数',
    }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" ～"+_vm._s(props.pageStop)+"件／全"+_vm._s(props.itemsLength)+"件 ")]}},{key:"item.edit",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"pa-1",attrs:{"disabled":_vm.bill_btn_disabled},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.shop_name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.shop_name)+" ")]}},{key:"item.plan_name",fn:function(ref){
    var item = ref.item;
return [(item.plan_code)?_c('span',{class:item.plan_code.slice(0, 2) == _vm.setting.plan_type_id_basic
            ? 'plan_name_primary'
            : 'plan_name_secondary'},[_vm._v(" "+_vm._s(item.plan_name)+" ")]):_vm._e()]}},{key:"item.fee_name",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.fee_name)+" ")]}},{key:"item.fee",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"right-input"},[_vm._v(" "+_vm._s(item.fee.toLocaleString())+" ")])]}},{key:"item.quantity",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"right-input"},[_vm._v(" "+_vm._s(item.quantity.toLocaleString())+" ")])]}},{key:"item.total",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"right-input"},[_vm._v(" "+_vm._s(item.total.toLocaleString())+" ")])]}},{key:"item.delete",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"pa-1",attrs:{"disabled":_vm.bill_btn_disabled},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_vm._v(" 表示するデータがありません ")]},proxy:true}])}),_c('detail-dialog-component',{ref:"refDetailDialog",attrs:{"user_list":_vm.props.users},on:{"get_list":_vm.getList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="6" sm="3">
          <general-button
            btn_type="info"
            btn_block
            :btn_disabled="bill_btn_disabled"
            @click-event="createData"
            ><template v-slot:icon
              ><v-icon left>mdi-receipt-text-check</v-icon></template
            >一括作成</general-button
          >
        </v-col>
        <v-col cols="6" sm="3">
          <general-button
            btn_color="error"
            btn_block
            :btn_disabled="bill_btn_disabled"
            @click-event="deleteData"
            ><template v-slot:icon
              ><v-icon left>mdi-receipt-text-remove</v-icon></template
            >一括削除</general-button
          >
        </v-col>
        <v-col cols="6" sm="3">
          <general-button
            btn_color="success"
            btn_block
            :btn_disabled="bill_btn_disabled"
            @click-event="newItem"
            ><template v-slot:icon><v-icon left>mdi-plus-box</v-icon></template
            >明細追加</general-button
          >
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="（キーワード検索）"
            single-line
            hide-details
            clearable
            dense
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :search="search"
      item-key="id"
      :mobile-breakpoint="0"
      :items-per-page="100"
      :footer-props="{
        'items-per-page-options': [100, 200, 400, -1],
        'items-per-page-text': '表示件数',
      }"
    >
      <template v-slot:footer.page-text="props">
        {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
          props.itemsLength
        }}件
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.edit="{ item }">
        <v-icon
          class="pa-1"
          @click="editItem(item)"
          :disabled="bill_btn_disabled"
        >
          mdi-pencil
        </v-icon>
      </template>
      <template v-slot:item.shop_name="{ item }">
        {{ item.shop_name }}
      </template>
      <template v-slot:item.plan_name="{ item }">
        <span
          v-if="item.plan_code"
          :class="
            item.plan_code.slice(0, 2) == setting.plan_type_id_basic
              ? 'plan_name_primary'
              : 'plan_name_secondary'
          "
        >
          {{ item.plan_name }}
        </span>
      </template>
      <template v-slot:item.fee_name="{ item }">
        {{ item.fee_name }}
      </template>
      <template v-slot:item.fee="{ item }">
        <div class="right-input">
          {{ item.fee.toLocaleString() }}
        </div>
      </template>
      <template v-slot:item.quantity="{ item }">
        <div class="right-input">
          {{ item.quantity.toLocaleString() }}
        </div>
      </template>
      <template v-slot:item.total="{ item }">
        <div class="right-input">
          {{ item.total.toLocaleString() }}
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.delete="{ item }">
        <v-icon
          class="pa-1"
          @click="deleteItem(item)"
          :disabled="bill_btn_disabled"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data> 表示するデータがありません </template>
    </v-data-table>
    <detail-dialog-component
      ref="refDetailDialog"
      v-bind:user_list="props.users"
      @get_list="getList"
    ></detail-dialog-component>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  toRefs,
  watch,
  defineComponent,
} from "@vue/composition-api";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import router from "@/router/index";
import utilDate from "@/common/utilDate";
import DetailDialogComponent from "./detailDialog";

export default defineComponent({
  props: {
    billing_month: {
      type: String,
      default: "",
    },
    close_date: {
      type: String,
      default: "",
    },
    user_id: {
      type: Number,
      default: null,
    },
    users: {
      type: Array,
      default: [],
    },
  },
  components: {
    DetailDialogComponent,
  },
  setup(props, ctx) {
    const billsRepository = repositoryFactory.get("bills");
    const refDetailDialog = ref();
    const state = reactive({
      bill_btn_disabled: true,
      search: "",
      headers: [
        {
          text: "編集",
          value: "edit",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "利用年月",
          value: "service_month_format",
          sortable: false,
          align: "center",
          width: 65,
        },
        {
          text: "登録ユーザー名",
          value: "user_name",
          sortable: true,
          class: "td_user_name",
        },
        {
          text: "店舗名",
          value: "shop_name",
          sortable: true,
          class: "td_shop_name",
        },
        {
          text: "プラン",
          value: "plan_name",
          sortable: true,
          class: "td_plan_name",
        },
        {
          text: "種別",
          value: "fee_name",
          sortable: true,
          class: "td_fee",
        },
        {
          text: "金額",
          value: "fee",
          sortable: true,
          class: "td_fee",
        },
        {
          text: "個数",
          value: "quantity",
          sortable: true,
          class: "td_fee",
        },
        {
          text: "合計",
          value: "total",
          sortable: true,
          class: "td_fee",
        },
        {
          text: "削除",
          value: "delete",
          sortable: false,
          align: "center",
          width: 60,
        },
      ],
      desserts: [], // DataTablesレコード格納用
      item: {
        id: 0,
        billing_month: null,
        service_month: null,
        invoice_date: null,
        user_id: null,
        shop_id: null,
        shop_contract_id: null,
        plan_code: "",
        fee_type: null,
        fee: null,
        quantity: null,
        total: null,
        shop_name: "",
        plan_name: "",
        fee_name: "",
        description: "",
        memo: "",
        user_name: "",
      },
    });

    const getList = async () => {
      if (!props.billing_month) return;

      if (props.close_date) {
        // 締め日が設定されている場合、締め日と請求書の日付を比べて、それ以前は請求データ作成不可
        if (props.close_date >= getInvoiceDate()) {
          state.bill_btn_disabled = true;
        } else {
          state.bill_btn_disabled = false;
        }
      } else {
        return;
      }

      store.dispatch("loadingIcon/showIcon"); // ローディング表示

      const params = {
        billing_month: props.billing_month.replace("-", ""),
        user_id: props.user_id,
      };

      state.desserts.splice(0);
      await billsRepository
        .admin_billing_list(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              const row = response.data[key];
              const strServiceMonth = row.service_month.toString();
              row.service_month_format =
                strServiceMonth.substr(0, 4) +
                "年" +
                strServiceMonth.substr(4, 2) +
                "月";
              state.desserts.push(row);
            });
          }
        })
        .catch((error) => {
          throw "ERROR:billsRepository.admin_billing_list (" + error + ")";
        });

      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    // 利用年月の監視
    watch(
      () => props.billing_month,
      async (newValue, oldValue) => {
        await getList();
      }
    );
    // ユーザーIDの監視
    watch(
      () => props.user_id,
      async (newValue, oldValue) => {
        await getList();
      }
    );

    const contractItem = (item) => {
      router.push({
        name: "admin-contract-edit",
        params: {
          shop_id: item.shop_id,
        },
      });
    };

    const init = async () => {
      refDetailDialog.value = DetailDialogComponent;
      await getList();
    };

    init();

    // 請求書の日付を取得
    const getInvoiceDate = () => {
      // props.billing_monthの前月の末日になる
      const invoice_date = new Date(props.billing_month + "-01");
      invoice_date.setDate(0);
      return utilDate.getDateFormatLong(invoice_date);
    };

    const createData = async () => {
      if (
        !window.confirm(
          "請求データを一括作成します。よろしいですか？\n\n" +
            "すでに作成済みの場合は上書きされます。\n" +
            "ユーザーを指定している場合、そのユーザーのみ作成します。"
        )
      ) {
        return;
      }
      store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // 請求データ作成
      const params = {
        billing_month: props.billing_month.replace("-", ""),
        user_id: props.user_id,
        invoice_date: getInvoiceDate(),
      };

      const result = await billsRepository
        .admin_make_bill(params)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw "ERROR:billsRepository.admin_make_bill (" + error + ")";
        });

      alert("請求データを一括作成しました。");

      await getList();

      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    const deleteData = async () => {
      if (
        !window.confirm(
          "請求データを一括削除します。よろしいですか？\nただし、手動で作成した請求データは削除されません。\n\n" +
            "※ユーザーを戦隊している場合、そのユーザーが削除対象になります。"
        )
      ) {
        return;
      }
      store.dispatch("loadingIcon/showIcon");

      const params = {
        billing_month: props.billing_month.replace("-", ""),
        user_id: props.user_id,
      };

      await billsRepository
        .admin_delete_all(params)
        .then((response) => {
          if (response.data) {
            // データ削除OK
            alert("請求データを一括削除しました。");
          }
        })
        .catch((error) => {
          throw "ERROR:billsRepository.admin_all_delete (" + error + ")";
        });

      await getList();
      store.dispatch("loadingIcon/hideIcon");
    };

    const newItem = async () => {
      refDetailDialog.value.newDialog(props.billing_month);
    };

    const editItem = async (item) => {
      refDetailDialog.value.showDialog(item);
    };

    const deleteItem = async (item) => {
      if (
        !window.confirm("選択した請求データを削除します。\nよろしいですか？")
      ) {
        return;
      }
      store.dispatch("loadingIcon/showIcon");

      await billsRepository
        .admin_delete(item.id)
        .then((response) => {
          if (response.data) {
            // データ削除OK
            alert("請求データを1件削除しました。");
          }
        })
        .catch((error) => {
          throw (
            "ERROR:admin/shop/index.vue/deleteData billsRepository.admin_delete (" +
            error +
            ")"
          );
        });
      await getList();
      store.dispatch("loadingIcon/hideIcon");
    };

    return {
      props,
      setting,
      refDetailDialog,
      ...toRefs(state),
      getList,
      contractItem,
      newItem,
      editItem,
      deleteItem,
      createData,
      deleteData,
    };
  },
});
</script>

<style scoped>
::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_user_name {
  min-width: 100px;
  width: 150px;
}
::v-deep .td_shop_name {
  min-width: 100px;
  width: 150px;
}
::v-deep .td_plan_name {
  min-width: 100px;
  width: 150px;
}
::v-deep .td_fee {
  min-width: 60px;
  width: 80px;
}
::v-deep .td_date {
  min-width: 120px;
  width: 160px;
}
.plan_name_primary {
  line-height: 30px;
  padding: 2px 4px;
  border-radius: 4px;
  margin: 2px;
  color: white;
  background-color: #1976d2;
}
.plan_name_secondary {
  line-height: 30px;
  padding: 2px 4px;
  border-radius: 4px;
  margin: 2px;
  color: white;
  background-color: #5cbbf6;
}
.sum_border {
  border-bottom: 1px solid #000000;
}
</style>

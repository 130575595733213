<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="6" sm="3"
          ><div :class="`${bill_btn_disabled ? 'closed' : 'no_closed'}`">
            {{
              bill_btn_disabled ? "締め済みのデータです" : "締め前のデータです"
            }}
          </div></v-col
        >
        <v-spacer></v-spacer>
        <v-col cols="6" sm="3">
          <general-button btn_type="info" btn_block @click-event="onExport"
            ><template v-slot:icon><v-icon left>mdi-download</v-icon></template
            >Excel出力</general-button
          >
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-col cols="6" sm="3">
          <h5 id="title_head">合計金額(税別)</h5>
          <p class="text-right mb-0 sum_border">
            <span class="text-h5">
              {{ total_fee.toLocaleString() }}
            </span>
            円
          </p>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="（キーワード検索）"
            single-line
            hide-details
            clearable
            dense
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :search="search"
      item-key="id"
      :mobile-breakpoint="0"
      :items-per-page="100"
      :footer-props="{
        'items-per-page-options': [100, 200, 400, -1],
        'items-per-page-text': '表示件数',
      }"
    >
      <template v-slot:footer.page-text="props">
        {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
          props.itemsLength
        }}件
      </template>
      <template v-slot:item.shop_names="{ item }">
        <div :class="item.row_type == 'sum' ? ` sum` : ``">
          {{ item.shop_names }}
        </div>
      </template>
      <template v-slot:item.plan_name="{ item }">
        <span
          v-if="item.plan_name.length > 0"
          :class="
            item.plan_code.slice(0, 2) == setting.plan_type_id_basic
              ? 'plan_name_primary'
              : 'plan_name_secondary'
          "
        >
          {{ item.plan_name }}
        </span>
        <div v-else :class="item.row_type == 'sum' ? ` sum` : ``">
          {{ item.sum_t.toLocaleString() }}円 (税別)
        </div>
      </template>
      <template v-slot:item.sc_total_initial_fee="{ item }">
        <div :class="`right-input` + (item.row_type == 'sum' ? ` sum` : ``)">
          {{ item.sc_total_initial_fee.toLocaleString() }}
        </div>
      </template>
      <template v-slot:item.fee_type="{ item }">
        {{
          item.fee_type &&
          selectItem.feeTypeItem.find((x) => x.value == item.fee_type).text
        }}
      </template>
      <template v-slot:item.fee="{ item }">
        <div class="right-input">
          {{ item.fee.toLocaleString() }}
        </div>
      </template>
      <template v-slot:item.sum_q="{ item }">
        <div class="right-input">
          {{ item.sum_q.toLocaleString() }}
        </div>
      </template>
      <template v-slot:item.sum_t="{ item }">
        <div :class="`right-input` + (item.row_type == 'sum' ? ` sum` : ``)">
          {{ item.sum_t.toLocaleString() }}
        </div>
      </template>
      <template v-slot:no-data> 表示するデータがありません </template>
    </v-data-table>
  </div>
</template>

<script>
import { reactive, toRefs, watch, defineComponent } from "@vue/composition-api";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import router from "@/router/index";
import utilDate from "@/common/utilDate";
import ExcelJS from "exceljs";
import selectItem from "@/common/selectItem";

export default defineComponent({
  props: {
    billing_month: {
      type: String,
      default: "",
    },
    close_date: {
      type: String,
      default: "",
    },
    user_id: {
      type: Number,
      default: null,
    },
  },
  setup(props, ctx) {
    const billsRepository = repositoryFactory.get("bills");
    const state = reactive({
      bill_btn_disabled: false,
      total_fee: 0,
      search: "",
      headers: [
        {
          text: "登録ユーザー名",
          value: "user_name",
          sortable: true,
          class: "td_user_name",
        },
        {
          text: "利用年月",
          value: "service_month_format",
          sortable: false,
          align: "center",
          width: 65,
        },
        {
          text: "店舗名",
          value: "shop_names",
          sortable: true,
          class: "td_shop_names",
        },
        {
          text: "プラン",
          value: "plan_name",
          sortable: true,
          class: "td_plan_name",
        },
        {
          text: "種別",
          value: "fee_type",
          sortable: true,
          class: "td_fee",
        },
        {
          text: "金額",
          value: "fee",
          sortable: true,
          class: "td_fee",
        },
        {
          text: "個数",
          value: "sum_q",
          sortable: true,
          class: "td_fee",
        },
        {
          text: "合計",
          value: "sum_t",
          sortable: true,
          class: "td_fee",
        },
      ],
      desserts: [], // DataTablesレコード格納用
    });

    // 請求書の日付を取得
    const getInvoiceDate = () => {
      // props.billing_monthの前月の末日になる
      const invoice_date = new Date(props.billing_month + "-01");
      invoice_date.setDate(0);
      return utilDate.getDateFormatLong(invoice_date);
    };

    const getList = async () => {
      state.total_fee = 0;

      if (!props.billing_month) return;

      if (props.close_date) {
        // 締め日が設定されている場合、締め日と請求書の日付を比べて、それ以前は請求データ作成不可
        const check_date = getInvoiceDate();
        if (props.close_date >= check_date) {
          state.bill_btn_disabled = true;
        } else {
          state.bill_btn_disabled = false;
        }
      } else {
        return;
      }

      store.dispatch("loadingIcon/showIcon"); // ローディング表示

      const params = {
        billing_month: props.billing_month.replace("-", ""),
        user_id: props.user_id,
      };

      state.desserts.splice(0);
      await billsRepository
        .admin_billing_list_summary(params)
        .then((response) => {
          if (response.data) {
            let keep_user_id = 0;
            let keep_user_name = "";
            let user_total = 0;
            for (let i = 0; i < response.data.length; i++) {
              const row = response.data[i];
              if (keep_user_id != row.user_id) {
                if (keep_user_id != 0) {
                  // 前のユーザーの月額合計を格納
                  state.desserts.push({
                    row_type: "sum",
                    user_name: "",
                    shop_names: keep_user_name + "合計金額",
                    plan_name: "",
                    fee: "",
                    sum_q: "",
                    sum_t: user_total,
                  });
                  state.total_fee += user_total;
                }
                keep_user_id = row.user_id;
                keep_user_name = row.user_name;
                user_total = 0;
              }
              // 月額合計を計算
              const strServiceMonth = row.service_month.toString();
              row.service_month_format =
                strServiceMonth.substr(0, 4) +
                "年" +
                strServiceMonth.substr(4, 2) +
                "月";
              row.shop_names = row.shop_names.join("、");
              state.desserts.push(row);
              user_total += row.sum_t;
            }
            if (keep_user_id != 0) {
              // 前のユーザーの月額合計を格納
              state.desserts.push({
                row_type: "sum",
                user_name: "",
                shop_names: keep_user_name + "合計金額",
                plan_name: "",
                fee: "",
                sum_q: "",
                sum_t: user_total,
              });
              state.total_fee += user_total;
            }
          }
        })
        .catch((error) => {
          throw (
            "ERROR:admin/shop/index.vue/deleteItem shopsRepository.admin_list (" +
            error +
            ")"
          );
        });

      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    // キャストID監視（0の時、ダイアログを閉じる。0以外の時、ダイアログを開く。）
    watch(
      () => props.billing_month,
      async (newValue, oldValue) => {
        await getList();
      }
    );
    // ユーザーIDの監視
    watch(
      () => props.user_id,
      async (newValue, oldValue) => {
        await getList();
      }
    );

    const onExport = async () => {
      if (state.desserts.length == 0) {
        alert("出力するデータがありません。");
        return;
      }

      // 現在日時をUNIX時間で取得
      const now = new Date();
      const now_unix = Math.floor(now.getTime() / 1000);
      const output_file = `sales_user_${now_unix}.xlsx`;

      // Excelファイル作成
      const workbook = new ExcelJS.Workbook();
      workbook.addWorksheet("請求合計一覧");
      const worksheet = workbook.getWorksheet("請求合計一覧");

      // ヘッダー行作成
      worksheet.columns = [
        { header: "請求年月", key: "year_month", width: 15 },
        { header: "利用年月", key: "service_month_format", width: 15 },
        { header: "ユーザー名", key: "user_name", width: 15 },
        { header: "店舗名", key: "shop_names", width: 30 },
        { header: "プラン", key: "plan_name", width: 15 },
        { header: "種別", key: "fee_type_name", width: 12 },
        { header: "金額", key: "fee", width: 12 },
        { header: "個数", key: "sum_q", width: 12 },
        { header: "合計", key: "sum_t", width: 12 },
      ];

      // データ行作成
      state.desserts.forEach((row) => {
        const row_data = row;
        row_data["year_month"] = props.billing_month.replace("-", "年") + "月";
        row_data["fee_type_name"] = selectItem.feeTypeItem.find(
          (x) => x.value == row.fee_type
        ).text;
        worksheet.addRow(row_data);
      });

      // エクセルを保存
      const blob = new Blob([await workbook.xlsx.writeBuffer()], {
        type: "application/octet-binary",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = output_file;
      a.click();
      a.remove();
    };

    const init = async () => {
      await getList();
    };

    init();

    return {
      setting,
      selectItem,
      ...toRefs(state),
      onExport,
    };
  },
});
</script>

<style scoped>
::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_user_name {
  min-width: 80px;
  width: 120px;
}
::v-deep .td_shop_names {
  min-width: 200px;
  width: 380px;
}
::v-deep .td_plan_name {
  min-width: 100px;
  width: 150px;
}
::v-deep .td_fee {
  min-width: 60px;
  width: 80px;
}
::v-deep .td_date {
  min-width: 120px;
  width: 160px;
}
.plan_name_primary {
  line-height: 30px;
  padding: 2px 4px;
  border-radius: 4px;
  margin: 2px;
  color: white;
  background-color: #1976d2;
}
.plan_name_secondary {
  line-height: 30px;
  padding: 2px 4px;
  border-radius: 4px;
  margin: 2px;
  color: white;
  background-color: #5cbbf6;
}
.sum_border {
  border-bottom: 1px solid #000000;
}
.sum {
  font-weight: bold;
  font-size: 1.25em;
}

.closed {
  font-weight: bold;
  color: red;
}
.no_closed {
  font-weight: bold;
  color: gray;
}
</style>

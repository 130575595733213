<template>
  <!-- 媒体登録ダイアログ -->
  <v-dialog v-model="dialog" scrollable persistent width="1000px">
    <v-card>
      <v-card-title>
        <span class="text-h5"
          >請求明細情報【{{ item.id == 0 ? "新規追加" : "編集" }}】</span
        >
      </v-card-title>
      <v-card-text class="scrollable-content">
        <input type="hidden" v-model="item.id" />
        <ValidationObserver ref="observer">
          <h3>請求時期</h3>
          <div class="mt-2 mb-2">
            請求年月は自動表示されます。利用年月を入力してください。
          </div>
          <v-container>
            <v-row>
              <v-col cols="6" sm="3">
                <v-text-field
                  v-model="item.billing_month_name"
                  readonly
                  success
                  name="billing_month_name"
                  label="請求年月"
                  outlined
                  hint="請求年月は自動表示されます。"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3">
                <v-menu v-model="menu">
                  <template v-slot:activator="{ on, attrs }">
                    <ValidationProvider
                      v-slot="{ errors, valid }"
                      name="利用年月"
                      rules="required"
                    >
                      <v-text-field
                        v-model="item.service_month_name"
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="errors"
                        :success="valid"
                        label="利用年月"
                        outlined
                        clearable
                        hint="利用年月を入力してください[必須選択]"
                        persistent-hint
                      >
                      </v-text-field>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                    v-model="picker"
                    type="month"
                    locale="jp-ja"
                    @input="formatDate(picker)"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
          <h3>明細情報</h3>
          <div class="mt-2 mb-2">
            請求明細を入力してください。店舗名は手入力が可能です。
          </div>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="ユーザー名"
                  rules="required"
                >
                  <v-select
                    :items="userList"
                    v-model="item.user_id"
                    label="ユーザー名選択"
                    outlined
                    :error-messages="errors"
                    :success="valid"
                    :readonly="item.id != 0"
                    hint="ユーザー名を選択してください。[必須選択]"
                    persistent-hint
                  ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="店舗名"
                  rules="max:100"
                >
                  <v-text-field
                    v-model="item.shop_name"
                    :error-messages="errors"
                    :success="valid"
                    name="shop_name"
                    label="店舗名"
                    outlined
                    hint="店舗名を入力してください。"
                    persistent-hint
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6">
                <v-select
                  :items="planSelectList"
                  v-model="item.plan_code"
                  label="プラン"
                  hint="プランを選択してください"
                  persistent-hint
                  outlined
                  success
                ></v-select>
              </v-col>
              <v-col cols="6" sm="3">
                <v-select
                  v-model="item.fee_type"
                  name="fee_type"
                  :items="selectItem.feeTypeItem"
                  no-data-text="未設定"
                  label="種別"
                  success
                  outlined
                  hint="種別を入力してください。"
                  persistent-hint
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <h3>金額情報</h3>
          <div class="mt-2 mb-2">請求金額を入力してください。</div>
          <v-container>
            <v-row>
              <v-col cols="6" sm="3">
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="金額"
                  rules="integer|required"
                >
                  <v-text-field
                    v-model="item.fee"
                    :error-messages="errors"
                    :success="valid"
                    name="fee"
                    label="金額"
                    outlined
                    type="number"
                    prefix="¥"
                    hint="金額を入力してください。"
                    persistent-hint
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="6" sm="3">
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="個数"
                  rules="integer|required"
                >
                  <v-text-field
                    v-model="item.quantity"
                    :error-messages="errors"
                    :success="valid"
                    name="quantity"
                    label="個数"
                    outlined
                    type="number"
                    min="1"
                    suffix="個"
                    hint="個数を入力してください。"
                    persistent-hint
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="6" sm="3">
                <v-text-field
                  v-model="item.total"
                  readonly
                  success
                  name="total"
                  label="合計"
                  outlined
                  type="number"
                  prefix="¥"
                  hint="合計は自動表示されます。"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <h3>コメント・補足</h3>
          <div class="mt-2 mb-2">
            コメント・補足を入力してください。メモは運営用として使用できます。
          </div>
          <v-container>
            <v-row>
              <v-col>
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="コメント・補足"
                  rules="max:10000"
                >
                  <v-textarea
                    v-model="item.description"
                    name="description"
                    :error-messages="errors"
                    :success="valid"
                    label="コメント・補足"
                    hint="必要に応じて、自由にコメントを入力して下さい。"
                    persistent-hint
                    outlined
                    rows="3"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="メモ（運営用）"
                  rules="max:10000"
                >
                  <v-textarea
                    v-model="item.memo"
                    name="memo"
                    :error-messages="errors"
                    :success="valid"
                    label="メモ（運営用）"
                    hint="必要に応じて、自由にコメントを入力して下さい。"
                    persistent-hint
                    outlined
                    rows="3"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-container>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row justify="end">
            <v-col cols="6" sm="3">
              <general-button
                btn_color="gray"
                btn_block
                @click-event="cancelDialog"
                ><template v-slot:icon
                  ><v-icon left>mdi-close-circle</v-icon></template
                >キャンセル</general-button
              >
            </v-col>
            <v-col cols="6" sm="3">
              <general-button btn_type="info" btn_block @click-event="onSubmit"
                ><template v-slot:icon
                  ><v-icon left>mdi-content-save</v-icon></template
                >保存</general-button
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  set,
  watch,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import selectItem from "@/common/selectItem.js";
import utilDate from "@/common/utilDate.js";

export default defineComponent({
  props: {
    user_list: {
      type: Array,
      default: [],
    },
  },
  setup(props, ctx) {
    const plansRepository = repositoryFactory.get("plans");
    const billsRepository = repositoryFactory.get("bills");
    const state = reactive({
      dialog: false, // ダイアログ表示用
      item: {
        id: 0,
        billing_month: null,
        service_month: null,
        invoice_date: null,
        user_id: null,
        shop_id: null,
        shop_contract_id: null,
        plan_code: null,
        fee_type: null,
        fee: null,
        quantity: null,
        total: null,
        shop_name: "",
        plan_name: "",
        fee_name: "",
        description: "",
        memo: "",
        user_name: "",
        billing_month_name: "",
        service_month_name: "",
        is_auto: false,
      },
      userList: [],
      shops: [],
      planList: [],
      planSelectList: [],
      menu: false,
      picker: null,
    });

    const newDialog = (billing_month) => {
      state.item.billing_month = billing_month.replace("-", "");
      state.item.billing_month_name = utilDate.getNumberMonthFormatFull(
        state.item.billing_month
      );
      state.picker = billing_month;
      state.item.service_month = billing_month.replace("-", "");
      state.item.service_month_name = utilDate.getNumberMonthFormatFull(
        state.item.service_month
      );
      const invoice_date = new Date(billing_month + "-01");
      invoice_date.setDate(0);
      state.item.invoice_date = utilDate.getDateFormatLong(invoice_date);

      state.dialog = true;
      scrollReset();
    };

    const showDialog = (item) => {
      state.item.id = item.id;
      state.item.billing_month = item.billing_month;
      state.item.service_month = item.service_month;
      state.item.invoice_date = item.invoice_date;
      state.item.user_id = item.user_id;
      state.item.shop_id = item.shop_id;
      state.item.shop_contract_id = item.shop_contract_id;
      state.item.plan_code = item.plan_code;
      state.item.fee_type = item.fee_type;
      state.item.fee = item.fee;
      state.item.quantity = item.quantity;
      state.item.total = item.total;
      state.item.shop_name = item.shop_name;
      state.item.plan_name = item.plan_name;
      state.item.fee_name = item.fee_name;
      state.item.description = item.description;
      state.item.memo = item.memo;
      state.item.is_auto = item.is_auto;
      state.item.user_name = item.user_name;

      state.item.billing_month_name = utilDate.getNumberMonthFormatFull(
        item.billing_month
      );
      state.item.service_month_name = utilDate.getNumberMonthFormatFull(
        item.service_month
      );

      console.log(state.item);

      state.dialog = true;
      scrollReset();
    };

    const onSubmit = async () => {
      // 入力チェック エラー状態の部品が無いか調べる
      const isValid = await ctx.refs.observer.validate();
      if (!isValid) {
        // エラー処理
        return;
      }

      if (!window.confirm("保存します。よろしいですか？")) {
        return;
      }

      store.dispatch("loadingIcon/showIcon");

      // 保存処理
      const params = {
        id: state.item.id,
        billing_month: state.item.billing_month,
        service_month: state.item.service_month,
        invoice_date: state.item.invoice_date,
        user_id: state.item.user_id,
        shop_id: state.item.shop_id,
        shop_contract_id: state.item.shop_contract_id,
        plan_code: state.item.plan_code,
        fee_type:
          state.item.fee_type == null ? null : Number(state.item.fee_type),
        fee: Number(state.item.fee),
        quantity: Number(state.item.quantity),
        total: Number(state.item.total),
        shop_name: state.item.shop_name,
        plan_name: state.item.plan_code
          ? state.planList.find((v) => v.code === state.item.plan_code).name
          : "",
        fee_name: state.item.fee_type
          ? selectItem.feeTypeItem.find((v) => v.value === state.item.fee_type)
              .text
          : "",
        description: state.item.description,
        memo: state.item.memo,
        is_auto: state.item.is_auto,
      };
      if (state.item.id == 0) {
        // 新規追加
        console.log(params);
        await billsRepository
          .admin_create(params)
          .then((response) => {
            if (response.data) {
              // データ追加OK
            }
          })
          .catch((error) => {
            throw (
              "ERROR:dialogComponent.vue/onSubmit templateRepository.create (" +
              error +
              ")"
            );
          });
      } else {
        // 更新
        await billsRepository
          .admin_update(params)
          .then((response) => {
            if (response.data) {
              // データ更新OK
            }
          })
          .catch((error) => {
            throw (
              "ERROR:dialogComponent.vue/onSubmit templateRepository.update (" +
              error +
              ")"
            );
          });
      }

      store.dispatch("loadingIcon/hideIcon");

      cancelDialog();

      ctx.emit("get_list");
    };

    const cancelDialog = () => {
      ctx.refs.observer.reset(); // 入力エラー表示クリア
      clearItem();
      state.dialog = false;
    };

    const scrollReset = () => {
      setTimeout(() => {
        document.getElementsByClassName("scrollable-content")[0].scrollTop = 0;
      }, 200);
    };

    const clearItem = () => {
      state.item.id = 0;
      state.item.billing_month = null;
      state.item.service_month = null;
      state.item.invoice_date = null;
      state.item.user_id = null;
      state.item.shop_id = null;
      state.item.shop_contract_id = null;
      state.item.plan_code = null;
      state.item.fee_type = null;
      state.item.fee = null;
      state.item.quantity = null;
      state.item.total = null;
      state.item.shop_name = "";
      state.item.plan_name = "";
      state.item.fee_name = "";
      state.item.description = "";
      state.item.memo = "";
      state.item.is_auto = false;
      state.item.user_name = "";
    };

    const init = async () => {
      store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // ユーザー一覧
      state.userList = props.user_list;

      // Planリスト取得
      state.planList.splice(0);
      state.planSelectList.splice(0);
      state.planSelectList.push({ value: null, text: "未選択" });
      await plansRepository
        .admin_list()
        .then((response) => {
          if (response.data) {
            // データ追加OK
            Object.keys(response.data).forEach(function (key) {
              const plan = response.data[key];
              const type_label = setting.plan_type_label.find(
                (x) => x.value === plan.code.slice(0, 2)
              ).text;

              set(plan, "type_label", type_label);
              state.planList.push(plan);

              const selectItem = {
                value: plan.code,
                text: type_label + " : " + plan.name,
              };
              state.planSelectList.push(selectItem);
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:dialogComponent.vue/onSubmit templateRepository.create (" +
            error +
            ")"
          );
        });

      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    // 利用年月の監視
    watch(
      () => state.item.fee,
      async (newValue, oldValue) => {
        state.item.total = newValue * state.item.quantity;
      }
    );
    // ユーザーIDの監視
    watch(
      () => state.item.quantity,
      async (newValue, oldValue) => {
        state.item.total = newValue * state.item.fee;
      }
    );

    init();

    // 年月入力部品
    const formatDate = async (date) => {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      state.item.service_month = `${year}${month}`;
      state.item.service_month_name = `${year}年${month}月`;
      state.menu = false;
      return;
    };

    // 返却オブジェクト定義
    return {
      props,
      selectItem,
      setting,
      ...toRefs(state),
      onSubmit,
      newDialog,
      cancelDialog,
      showDialog,
      scrollReset,
      formatDate,
    };
  },
});
</script>

<style scoped>
::v-deep #color-selector .v-input__append-inner {
  margin-top: 13px;
}
.edit_area {
  cursor: pointer;
}
::v-deep .td_content_name {
  width: 160px;
}
::v-deep .td_site_content_name {
  min-width: 160px;
}
</style>

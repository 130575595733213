<template>
  <div>
    <v-card elevation="0">
      <div class="text-subtitle-2 pa-1 blue--text blue lighten-5">
        「請求年月」は請求したい月を選択してください。※締め日＋1ヶ月を初期表示しています。
      </div>
      <v-card-title>
        <v-container fluid>
          <v-row align="center">
            <v-col cols="6" sm="3" md="3">
              <v-menu v-model="menu">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="text"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    label="請求年月（請求書の日付）"
                    hide-details
                    style="max-width: 290px"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="picker"
                  type="month"
                  locale="jp-ja"
                  @input="formatDate(picker)"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" sm="3" md="3">
              <v-select
                :items="users"
                v-model="user_id"
                label="ユーザー名選択"
                hide-details
                dense
              ></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6" sm="3" md="2">
              <v-text-field
                v-model="close_date"
                readonly
                label="現在の締め日"
                filled
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <general-button btn_type="info" btn_block @click-event="closing"
                ><template v-slot:icon><v-icon left>mdi-lock</v-icon></template
                >締め処理</general-button
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>

      <v-tabs v-model="tabItems" class="mb-3">
        <v-tabs-slider></v-tabs-slider>
        <v-tab v-for="tab in tabs" :key="tab.index" class="font-weight-bold">{{
          tab.name
        }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabItems" touchless>
        <v-tab-item v-for="tab in tabs" :key="tab.index">
          <component
            v-bind:is="tab.component"
            v-bind:billing_month="picker"
            v-bind:close_date="close_date"
            v-bind:user_id="user_id"
            v-bind:users="users"
          ></component>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  toRefs,
  defineComponent,
  set,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate";
import router from "@/router/index";
import DetailComponent from "./component/detail.vue";
import SummaryComponent from "./component/summary.vue";

export default defineComponent({
  components: {
    DetailComponent,
    SummaryComponent,
  },
  setup(_, ctx) {
    const usersRepository = repositoryFactory.get("users");
    const systemPropsRepository = repositoryFactory.get("systemProps");
    const tabItems = ref();
    const tabs = [
      {
        index: 1,
        name: "請求明細",
        component: DetailComponent,
      },
      {
        index: 2,
        name: "請求合計",
        component: SummaryComponent,
      },
    ];
    const state = reactive({
      users: [],
      user_id: null,
      close_date: "",
      newClosingDate: "",
      menu: false,
      text: null,
      picker: null,
    });

    // 年月入力部品
    const formatDate = async (strMonth) => {
      if (!strMonth) return null;
      const [year, month] = strMonth.split("-");
      const tmp = utilDate.convertStrToDate(strMonth + "-01");
      tmp.setDate(0);
      const billing_date_str = utilDate.getDateFormatLong(tmp);
      state.text = `${year}年${month}月（${billing_date_str}）`;
      state.menu = false;
      return;
    };

    // 初期化処理(同期処理)
    const init = async () => {
      store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // ユーザー取得
      state.users.splice(0);
      await usersRepository
        .admin_list()
        .then((response) => {
          if (response.data) {
            state.users.push({ value: null, text: "未選択" });
            Object.keys(response.data).forEach(function (key) {
              state.users.push({
                value: response.data[key].id,
                text: response.data[key].name,
              });
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:admin/shop/index.vue/init usersRepository.admin_list (" +
            error +
            ")"
          );
        });

      // 締め日プラス1ヶ月を利用年月に入れる
      // 現在の締め日を取得
      const result = await systemPropsRepository
        .admin_get_key("closing_date")
        .then((response) => {
          if (response.data) {
            return response.data.value;
          } else {
            return null;
          }
        })
        .catch((error) => {
          throw (
            "ERROR:closing/index.vue/init systemPropsRepository.admin_get_key (" +
            error +
            ")"
          );
        });
      if (!result) {
        alert("締め日が設定されていません。");
        store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
        return;
      }

      // 現在の締め日を記憶(YYYY-MM-DD形式)
      state.close_date = result;
      // state.close_dateの翌々月を請求年月に設定
      const next = new Date(state.close_date);
      next.setDate(1);
      next.setMonth(next.getMonth() + 2);
      state.picker = utilDate.getMonthFormatLong(next);
      await formatDate(state.picker);

      // 次回の締め日を記憶
      next.setDate(0);
      state.newClosingDate = utilDate.getDateFormatLong(next);

      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    init();

    const contractItem = (item) => {
      router.push({
        name: "admin-contract-edit",
        params: {
          shop_id: item.shop_id,
        },
      });
    };

    const updateClosingDate = async (closingDate) => {
      const param = {
        key: "closing_date",
        value: closingDate,
      };
      await systemPropsRepository
        .admin_update_key(param)
        .then((response) => {
          if (response.data) {
            // データ追加OK
          }
        })
        .catch((error) => {
          throw (
            "ERROR:closing/index.vue/updateClosingDate systemPropsRepository.admin_update_key (" +
            error +
            ")"
          );
        });
    };

    // 締め処理を行う
    const closing = async () => {
      if (
        !window.confirm(
          "締め処理を行います。よろしいですか？\n" +
            state.newClosingDate +
            " に設定します。"
        )
      ) {
        return;
      }

      await updateClosingDate(state.newClosingDate);

      alert("締め処理を行いました。");
      init();
    };

    // 返却オブジェクト定義
    return {
      setting,
      ...toRefs(state),
      init,
      formatDate,
      contractItem,
      sales_tab: "contract",
      tabItems,
      tabs,
      closing,
    };
  },
});
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","persistent":"","width":"1000px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("請求明細情報【"+_vm._s(_vm.item.id == 0 ? "新規追加" : "編集")+"】")])]),_c('v-card-text',{staticClass:"scrollable-content"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.id),expression:"item.id"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.item.id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.item, "id", $event.target.value)}}}),_c('ValidationObserver',{ref:"observer"},[_c('h3',[_vm._v("請求時期")]),_c('div',{staticClass:"mt-2 mb-2"},[_vm._v(" 請求年月は自動表示されます。利用年月を入力してください。 ")]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-text-field',{attrs:{"readonly":"","success":"","name":"billing_month_name","label":"請求年月","outlined":"","hint":"請求年月は自動表示されます。","persistent-hint":""},model:{value:(_vm.item.billing_month_name),callback:function ($$v) {_vm.$set(_vm.item, "billing_month_name", $$v)},expression:"item.billing_month_name"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"利用年月","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"error-messages":errors,"success":valid,"label":"利用年月","outlined":"","clearable":"","hint":"利用年月を入力してください[必須選択]","persistent-hint":""},model:{value:(_vm.item.service_month_name),callback:function ($$v) {_vm.$set(_vm.item, "service_month_name", $$v)},expression:"item.service_month_name"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"type":"month","locale":"jp-ja"},on:{"input":function($event){return _vm.formatDate(_vm.picker)}},model:{value:(_vm.picker),callback:function ($$v) {_vm.picker=$$v},expression:"picker"}})],1)],1)],1)],1),_c('h3',[_vm._v("明細情報")]),_c('div',{staticClass:"mt-2 mb-2"},[_vm._v(" 請求明細を入力してください。店舗名は手入力が可能です。 ")]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"ユーザー名","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.userList,"label":"ユーザー名選択","outlined":"","error-messages":errors,"success":valid,"readonly":_vm.item.id != 0,"hint":"ユーザー名を選択してください。[必須選択]","persistent-hint":""},model:{value:(_vm.item.user_id),callback:function ($$v) {_vm.$set(_vm.item, "user_id", $$v)},expression:"item.user_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"店舗名","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"name":"shop_name","label":"店舗名","outlined":"","hint":"店舗名を入力してください。","persistent-hint":""},model:{value:(_vm.item.shop_name),callback:function ($$v) {_vm.$set(_vm.item, "shop_name", $$v)},expression:"item.shop_name"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.planSelectList,"label":"プラン","hint":"プランを選択してください","persistent-hint":"","outlined":"","success":""},model:{value:(_vm.item.plan_code),callback:function ($$v) {_vm.$set(_vm.item, "plan_code", $$v)},expression:"item.plan_code"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-select',{attrs:{"name":"fee_type","items":_vm.selectItem.feeTypeItem,"no-data-text":"未設定","label":"種別","success":"","outlined":"","hint":"種別を入力してください。","persistent-hint":""},model:{value:(_vm.item.fee_type),callback:function ($$v) {_vm.$set(_vm.item, "fee_type", $$v)},expression:"item.fee_type"}})],1)],1)],1),_c('h3',[_vm._v("金額情報")]),_c('div',{staticClass:"mt-2 mb-2"},[_vm._v("請求金額を入力してください。")]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('ValidationProvider',{attrs:{"name":"金額","rules":"integer|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"name":"fee","label":"金額","outlined":"","type":"number","prefix":"¥","hint":"金額を入力してください。","persistent-hint":""},model:{value:(_vm.item.fee),callback:function ($$v) {_vm.$set(_vm.item, "fee", $$v)},expression:"item.fee"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('ValidationProvider',{attrs:{"name":"個数","rules":"integer|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"name":"quantity","label":"個数","outlined":"","type":"number","min":"1","suffix":"個","hint":"個数を入力してください。","persistent-hint":""},model:{value:(_vm.item.quantity),callback:function ($$v) {_vm.$set(_vm.item, "quantity", $$v)},expression:"item.quantity"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('v-text-field',{attrs:{"readonly":"","success":"","name":"total","label":"合計","outlined":"","type":"number","prefix":"¥","hint":"合計は自動表示されます。","persistent-hint":""},model:{value:(_vm.item.total),callback:function ($$v) {_vm.$set(_vm.item, "total", $$v)},expression:"item.total"}})],1)],1)],1),_c('h3',[_vm._v("コメント・補足")]),_c('div',{staticClass:"mt-2 mb-2"},[_vm._v(" コメント・補足を入力してください。メモは運営用として使用できます。 ")]),_c('v-container',[_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"コメント・補足","rules":"max:10000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{attrs:{"name":"description","error-messages":errors,"success":valid,"label":"コメント・補足","hint":"必要に応じて、自由にコメントを入力して下さい。","persistent-hint":"","outlined":"","rows":"3"},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"メモ（運営用）","rules":"max:10000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{attrs:{"name":"memo","error-messages":errors,"success":valid,"label":"メモ（運営用）","hint":"必要に応じて、自由にコメントを入力して下さい。","persistent-hint":"","outlined":"","rows":"3"},model:{value:(_vm.item.memo),callback:function ($$v) {_vm.$set(_vm.item, "memo", $$v)},expression:"item.memo"}})]}}])})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-container',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('general-button',{attrs:{"btn_color":"gray","btn_block":""},on:{"click-event":_vm.cancelDialog},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-circle")])]},proxy:true}])},[_vm._v("キャンセル")])],1),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('general-button',{attrs:{"btn_type":"info","btn_block":""},on:{"click-event":_vm.onSubmit},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")])]},proxy:true}])},[_vm._v("保存")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }